<template>
  <div class="swbb_table_width zyhz-table-container">
    <div class="top_title3">
      <h4>成员权益变动表</h4>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center"><span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <el-scrollbar :style="{height:contentStyleObj}">
      <table class="content" cellspacing="0">
        <tr class="top_bg">
          <td class="center weight" style="width:10%;" >项目</td>
          <td class="center weight" style="width:16%;" colspan="2">股金</td>
          <td class="center weight" style="width:16%;" colspan="2">专项基金</td>
          <td class="center weight" style="width:16%;" colspan="2">资本公积</td>
          <td class="center weight" style="width:16%;" colspan="2">盈余公积</td>
          <td class="center weight" style="width:16%;" colspan="2">未分配盈余</td>
          <td class="center weight" style="width:10%;">合计</td>
        </tr>
        <tr>
          <td style="width:10%;" class="bg_color">年初余额</td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[0].gj" @change="() => changeList(0, 'gj')"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[0].zxjj" @change="() => changeList(0, 'zxjj')"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[0].zbgj" @change="() => changeList(0, 'zbgj')"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[0].yygj" @change="() => changeList(0, 'yygj')"></qzf-str-td>
          <!-- <td class="right bg_color" colspan="2">{{ getNumberShow(list[0].wfpyy) }}</td> -->
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[0].wfpyy" @change="() => changeList(0, 'wfpyy')"></qzf-str-td>
          <td class="right bg_color" colspan="2">{{ getNumberShow(list[0].total) }}</td>
        </tr>
        <tr>
          <td class="bg_color">本年增加数</td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[1].gj" @change="() => changeList(1, 'gj')"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[1].zxjj" @change="() => changeList(1, 'zxjj')"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[1].zbgj" @change="() => changeList(1, 'zbgj')"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[1].yygj" @change="() => changeList(1, 'yygj')"></qzf-str-td>
          <!-- <td class="right bg_color" colspan="2">{{ getNumberShow(list[1].wfpyy) }}</td> -->
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[0].wfpyy" @change="() => changeList(1, 'wfpyy')"></qzf-str-td>
          <td class="right bg_color" colspan="2">{{ getNumberShow(list[1].total) }}</td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td colspan="2" class="bg_color">其中：</td>
          <td colspan="2" class="bg_color">其中：</td>
          <td colspan="2" class="bg_color">其中：</td>
          <td colspan="2" class="bg_color">其中：</td>
          <td colspan="2" class="bg_color"></td>
          <td class="bg_color"></td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td style="width:8%" class="bg_color">资本公积转增</td>
          <qzf-str-td :disabled="false" v-model:amount="list[3].gj" @change="() => calTotal(3)"></qzf-str-td>
          <td style="width:11%" class="bg_color">接受国家财政直接补助</td>
          <qzf-str-td :disabled="false" v-model:amount="list[3].zxjj" @change="() => calTotal(3)"></qzf-str-td>
          <td style="width:8%" class="bg_color">股金溢价</td>
          <qzf-str-td :disabled="false" v-model:amount="list[3].zbgj" @change="() => calTotal(3)"></qzf-str-td>
          <td style="width:8%" class="bg_color">从盈余中提取</td>
          <qzf-str-td :disabled="false" v-model:amount="list[3].yygj" @change="() => calTotal(3)"></qzf-str-td>
          <td colspan="2" class="bg_color"></td>
          <td class="right bg_color">{{ getNumberShow(list[3].total) }}</td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td class="bg_color">盈余公积转增</td>
          <qzf-str-td :disabled="false" v-model:amount="list[4].gj" @change="() => calTotal(4)"></qzf-str-td>
          <td class="bg_color">接受捐赠转入</td>
          <qzf-str-td :disabled="false" v-model:amount="list[4].zxjj" @change="() => calTotal(4)"></qzf-str-td>
          <td class="bg_color">资产评估增值</td>
          <qzf-str-td :disabled="false" v-model:amount="list[4].zbgj" @change="() => calTotal(4)"></qzf-str-td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td class="right bg_color">{{ getNumberShow(list[4].total) }}</td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td class="bg_color">成员增加出资</td>
          <qzf-str-td :disabled="false" v-model:amount="list[5].gj" @change="() => calTotal(5)"></qzf-str-td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td class="right bg_color" colspan="2">{{ getNumberShow(list[5].total) }}</td>
        </tr>

        <tr>
          <td class="bg_color">本年减少数</td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[6].gj" @change="() => changeList(6, 'gj')"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[6].zxjj" @change="() => changeList(6, 'zxjj')"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[6].zbgj" @change="() => changeList(6, 'zbgj')"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[6].yygj" @change="() => changeList(6, 'yygj')"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[6].wfpyy" @change="() => changeList(6, 'wfpyy')"></qzf-str-td>
          <td class="right bg_color" colspan="2">{{ getNumberShow(list[6].total) }}</td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color">其中：</td>
          <td colspan="2" class="bg_color"></td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td class="bg_color" style="width:8%">按交易量（额）分配的盈余：</td>
          <qzf-str-td :disabled="false" v-model:amount="list[8].wfpyy" @change="() => calTotal(8)"></qzf-str-td>
          <td class="right bg_color" colspan="2">{{ getNumberShow(list[8].total) }}</td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td class="bg_color" style="width:8%">剩余盈余分配</td>
          <!-- <td class="right bg_color">{{ list[9].wfpyy }}</td> -->
          <qzf-str-td :disabled="false" v-model:amount="list[9].wfpyy" @change="() => calTotal(9)"></qzf-str-td>
          <td class="right bg_color" colspan="2">{{ getNumberShow(list[9].total) }}</td>
        </tr>
        <tr>
          <td class="bg_color">年末余额</td>
          <td class="right bg_color" colspan="2">{{ getNumberShow(list[10].gj) }}</td>
          <td class="right bg_color" colspan="2">{{ getNumberShow(list[10].zxjj) }}</td>
          <td class="right bg_color" colspan="2">{{ getNumberShow(list[10].zbgj) }}</td>
          <td class="right bg_color" colspan="2">{{ getNumberShow(list[10].yygj) }}</td>
          <td class="right bg_color" colspan="2">{{ getNumberShow(list[10].wfpyy) }}</td>
          <td class="right bg_color" colspan="2">{{ getNumberShow(list[10].total) }}</td>
        </tr>
      </table>
    </el-scrollbar>
    <div class="save_box" v-if="this.typeName != '历史数据'&& $buttonStatus('kjbb_bc')">
      <el-button type="primary" @click="saveValues()" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
import { hisLrbInfo } from '@/api/old.js'
export default {
  name: 'culturalMain',
  props: {
    typeName: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      durationOfTaxTime: '',
      activeName: '1',
      name: '1',
      listQuery: {},
      list: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},],
      attachArr: [],
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      contentStyleObj:{}, //高度变化
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(340)
  },
  methods: {
    getNumberShow(num) {
      if (typeof num === 'number') {
        return num.toFixed(2)
      }
      if (num === '') {
        return ''
      }
      return num
    },
    // 公式
    changeList(i, key){
      this.calTotal(i)
      this.list[10][key] = (+this.list[0][key] + +this.list[1][key] - +this.list[6][key]).toFixed(2)
    },
    calTotal(i) {
      const keysArr = ['gj', 'zxjj', 'zbgj', 'yygj', 'wfpyy']
      this.list[i].total = keysArr.reduce((acc, k) => {
        acc += +(this.list[i][k] || 0)
        return acc
      }, 0).toFixed(2)
    },
    getList(period){
      if(this.typeName == '历史数据'){
        let periodZC
        if(period){
          periodZC = period
        } else {
          periodZC = this.$store.getters['user/comInfo'].period;
        }
        hisLrbInfo({period:periodZC}).then(res=>{
          if(res.data.data.info ){
            this.list = res.data.data.info
            // this.type = res.data.data.type
          }
        })
      }else{
        if(this.startAccountPeriod){
        let param = {
          tableName: 'kj_lrb',
          itemName:'cy',
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }else{
        gsInfo({tableName: 'kj_lrb',itemName:'cy'}).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
      }
   
    },
    saveValues(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'kj_lrb',
          itemName:'cyzy',
          items: this.list,
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'kj_lrb',
          itemName:'cyzy',
          items: this.list
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 13px !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  background-color: #fff;
  span{
    line-height: 26px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #000;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 26px;
    padding: 0 6px;
    font-size: 13px;
  }
}
.bg_color{
  background-color: #d6e2ea;
}
</style>