<template>
    <div class="big_box4">
      <div class="top_title3">
        <h4>资源税</h4>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content" cellspacing="0" width="100%">
        <div class="add" @click="editAddItem(editInfo)">
          <i class="iconfont icon-jiahao"></i>
        </div>
        <tr class="top_bg">
          <td class="center weight width_mini"  style="width:3%;" >序号</td>
          <td class="center weight width_small" style="width:10%;">税目</td>
          <td class="center weight width_date"  style="width:20%;">税款所属期起</td>
          <td class="center weight width_date"  style="width:20%;">税款所属期止</td>
          <td class="center weight width_big"   style="width:30%;">减免性质代码和项目名称</td>
          <td class="center weight width_small" style="width:7%;" >减免税额</td>
        </tr>
  
        <tr v-for="(item,index) in editInfo" :key="index" class="close_item">
          <td class="width_mini center">{{index + 1}}</td>
          <td class="width_small"><input type="text" v-model="item.number"></td>
          <td class="width_date">
            <el-date-picker value-format="YYYY-MM-DD" size="small" v-model="item.taxStart" type="date" placeholder="选择日期时间"></el-date-picker>
          </td>
          <td class="width_date">
            <el-date-picker value-format="YYYY-MM-DD" size="small" v-model="item.taxEnd" type="date" placeholder="选择日期时间"></el-date-picker>
          </td>
          <td class="width_big">
            <jmxzdm type="gs_deed_zys" v-model:id="item.jmxzdmId" style="width:100%"></jmxzdm>
          </td>
          <qzf-str-td class="width_small" :disabled="false" v-model:amount="item.jmse" @change="changeList()"></qzf-str-td>
          <div class="close" @click="editDelItem(editInfo,index)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
  
        <tr>
          <td>小计</td>
          <!-- <td>--</td> -->
          <td>--</td>
          <td>--</td>
          <td>--</td>
          <td>--</td>
          <td>{{totalAmount7}}</td>
        </tr>
      </table>
    </div>
    <div class="save_box" v-if="$buttonStatus('swbb_bc')">
      <el-button type="primary" @click="saveFb4()" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
    </div>
  </template>
  
  <script>
  import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
  export default {
    name:'fb4',
    props: {
      startAccountPeriod:{
        default:'',
        type:String
      },
      comId:{
        default:0,
        type:Number
      },
    },
    computed:{
      totalAmount7() {
        let jmseTotal2 = 0;
        this.editInfo.map(v => {
          if (v.jmse) {
            jmseTotal2 += Number(v.jmse);
          }
        });
        return parseFloat(jmseTotal2.toFixed(2))
      },
    },
    data() {
      return {
        editInfo:[{
          // type:'',
          number:'',//新增一个字段
          taxStart:'',
          taxEnd:'',
          jmxzdm:'',
          jmse:'',
        }],
        comName:this.$store.getters['user/comInfo'].comName,
        taxNo:this.$store.getters['user/comInfo'].taxNo,
      }
    },
    methods:{
      changeList(){
  
      },
      editAddItem(item) {
        item.push({
          // type:'',
          number:'',
          taxStart:'',
          taxEnd:'',
          jmxzdm:'',
          jmse:'',
        });
      },
      editDelItem(item, index) {
        if (item.length <= 1) {
          this.$message.error("仅剩一项禁止删除");
          return;
        }
        item.splice(index, 1);
      },
      getList(){
        if(this.startAccountPeriod){
          let param = {
            tableName: 'gs_deed',
            period:this.startAccountPeriod,
            itemName:'fb4',
            comId:this.comId
          }
          gsInfo(param).then(res=>{
            if(res.data.data.info){
              this.editInfo = res.data.data.info
              this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            }
          })
        }else{
          gsInfo({tableName: 'gs_deed',itemName:'fb4'}).then(res=>{
            if(res.data.data.info){
              this.editInfo = res.data.data.info
            }
          })
        }
      },
      saveFb4(){
        if(this.startAccountPeriod){
          let param = {
            tableName:'gs_deed',
            itemName:'fb4',
            items:this.editInfo,
            period:this.startAccountPeriod,
            comId:this.comId,
          }
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else{
          let param = {
            tableName:'gs_deed',
            itemName:'fb4',
            items:this.editInfo
          }
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }
    }
  }
  </script>
  
<style lang="scss" scoped>

.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
  
}
.width_mini{
  width:4%;
}
.width_small{
  width: 20%;
}
.width_big{
  width: 30%;
}
.width_date{
  width: 10%;
}
.save_box{
  width: 98%;
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 13px !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: -28px;
    top: 9px;
    font-size: 20px;
    color: #f15a24;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
      color: var(--themeColor,#17a2b8);
    }
  }
  span{
    line-height: 26px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #666;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 26px;
    padding: 0 6px;
    font-size: 13px;
  }
}
.close_item{
  position: relative;
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -32px;
    top: 0;
    font-size: 20px;
    color: #ff1818;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
      color: var(--themeColor,#17a2b8);
    }
  }
}

.big_box4{
  width: 98%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}

</style>
  